import {
  ClientType,
  DashboardGraphTypes,
  DownSamplingTimeFrame,
  WebSocketMessageType,
  defaultOverallSummaryAggregation,
} from '@finance-ops/types';

import { Box, Grid, useMediaQuery, useTheme } from '@mui/material';
import { endOfDay, startOfDay, subDays } from 'date-fns';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import useLocalStorageDashboardState from '../hooks/dashboard';
import { useAuth } from '../hooks/useAuth';
import { httpPost } from '../httpClient';
import api from '../httpClient/api';
import { RootState } from '../store';
import { clientSelectors } from '../store/client';
import {
  resetDashboardAnalytics,
  setCollections,
  setDashboardToLoading,
  setGraphLoading,
  setOverviewData,
} from '../store/dashboard';
import { convertDates } from '../utils/date-utils';
import { setupPushNotifications } from '../utils/service-worker/service-worker-utils';
import AccountSummary from '../views/dashboard/components/AccountSummary';
import ActionCenterTickets from '../views/dashboard/components/ActionCenterTickets';
import AgingSummary from '../views/dashboard/components/AgingSummary';
import CollectMatrix from '../views/dashboard/components/CollectMatrix';
import CollectionPerformance from '../views/dashboard/components/CollectionsPerformance';
import CustomerEngagement from '../views/dashboard/components/CustomerEngagement';
import DelinquentRate from '../views/dashboard/components/DelinquentRate';
import Header from '../views/dashboard/components/Header';
import NumberOfInvoices from '../views/dashboard/components/NumberOfInvoices';
import OverviewSection from '../views/dashboard/components/OverviewSections';
import PaymentsGraph from '../views/dashboard/components/Payments';
import RecentPayments from '../views/dashboard/components/RecentPayments';
import StatusOfCustomers from '../views/dashboard/components/StatusOfCustomers';
import TopDebtor from '../views/dashboard/components/TopDebtor';
import TopPaymentsMethod from '../views/dashboard/components/TopPaymentsMethod';
import VisibilitySettingsModal from '../views/dashboard/components/VisibilitySettingModal';
import { sendMessage } from '../websocket/WebSocketClient';
interface DateRanges {
  main: [Date | null, Date | null];
  compare?: [Date | null, Date | null];
}

const Dashboard = () => {
  // ** Hooks
  const { user } = useAuth();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const dispatch = useDispatch();
  const isInternalUser = user?.role?.isInternal;

  const clients: ClientType[] = useSelector(clientSelectors.selectAll);
  const selectedClientId = useSelector((state: RootState) => state.client.selectedClientId);
  const uiMode = useSelector((state: RootState) => state.dashboard.uiMode);
  const agingSummary = useSelector((state: RootState) => state.dashboard.aggregation.agingSummary);
  const loading = useSelector((state: RootState) => state.dashboard.loading);

  const { getLocalStorageDashboardState, setLocalStorageDashboardState } = useLocalStorageDashboardState();

  const [dateRangesCollections, setDateRangesCollections] = useState<DateRanges>(() => {
    const localStorageDashboardState = getLocalStorageDashboardState();
    if (localStorageDashboardState.dateRangesCollections) {
      return convertDates(localStorageDashboardState.dateRangesCollections);
    }
    return { main: [new Date(), new Date()], compare: [subDays(new Date(), 1), subDays(new Date(), 1)] };
  });

  const [downSamplingSize, setDownSamplingSize] = useState<DownSamplingTimeFrame>(() => {
    const localStorageDashboardState = getLocalStorageDashboardState();
    if (localStorageDashboardState.downSamplingSize) {
      return localStorageDashboardState.downSamplingSize;
    }
    return DownSamplingTimeFrame.DAILY;
  });

  useEffect(() => {
    setupPushNotifications();
  }, []);

  useEffect(() => {
    if (user) {
      dispatch(resetDashboardAnalytics());
      dispatch(setDashboardToLoading(true));
      if (isInternalUser) {
        sendMessage(WebSocketMessageType.GET_DASHBOARD_SUMMARY, {
          uiMode,
          clientIdDropdown: selectedClientId,
        });
      } else {
        sendMessage(WebSocketMessageType.GET_DASHBOARD_SUMMARY, {
          uiMode,
        });
      }
    }
  }, [selectedClientId, dispatch, user, uiMode, isInternalUser]);

  useEffect(() => {
    [
      DashboardGraphTypes.ACTION_CENTER_GRAPH,
      DashboardGraphTypes.CUSTOMER_STATUS_GRAPH,
      DashboardGraphTypes.TOP_DEBTORS,
      DashboardGraphTypes.RECENT_PAYMENTS,
      DashboardGraphTypes.PAYMENT_TYPES_GRAPH,
      DashboardGraphTypes.PAYMENT_STATUS_GRAPH,
      //REMOVE RECEIVABLES
      DashboardGraphTypes.RECEIVABLES_GRAPH,
    ].map(type => {
      dispatch(setGraphLoading({ graphType: type }));
      sendMessage(WebSocketMessageType.GET_DASHBOARD_GRAPH, {
        uiMode,
        clientIdDropdown: selectedClientId,
        graphType: type,
      });
    });
  }, [dispatch, uiMode, selectedClientId, user]);

  const getCollectionsGraph = () => {
    if (dateRangesCollections.main[0]) {
      dispatch(setCollections({ loading: true, data: [] }));
      sendMessage(WebSocketMessageType.GET_DASHBOARD_SYNC, {
        uiMode,
        clientIdDropdown: selectedClientId,
        filters: {
          main: [
            startOfDay(dateRangesCollections.main[0]),
            endOfDay(dateRangesCollections.main[1] ?? dateRangesCollections.main[0]),
          ],
          compare: [
            startOfDay(dateRangesCollections?.compare?.[0] ?? subDays(dateRangesCollections.main[0], 1)),
            endOfDay(
              dateRangesCollections?.compare?.[1] ??
                dateRangesCollections?.compare?.[0] ??
                subDays(dateRangesCollections.main[0], 2),
            ),
          ],
        },
        downSamplingSize,
      });
    }
  };

  useEffect(() => {
    getCollectionsGraph();
    setLocalStorageDashboardState({ dateRangesCollections, downSamplingSize });
  }, [dispatch, uiMode, selectedClientId, dateRangesCollections, downSamplingSize]);

  useEffect(() => {
    dispatch(setOverviewData({ loading: true, data: defaultOverallSummaryAggregation }));
    httpPost(api.aggregator + '/dashboardOverview', {
      clientId: selectedClientId || null,
      uiMode: uiMode,
    }).then(res => {
      dispatch(setOverviewData({ loading: false, data: res.data }));
    });
  }, [selectedClientId, uiMode]);

  const [modalOpen, setModalOpen] = useState(false);
  const [visibleComponents, setVisibleComponents] = useState({
    collectionPerformance: true,
    accountSummary: true,
    oviewSection: true,
    agingSummary: true,
    collectabilityMatrix: true,
    topDebtor: true,
    customerEngagement: true,
    recentPayments: true,
    paymentsGraph: true,
    delinquentRate: true,
    topPaymentsMethod: true,
    numberOfInvoices: true,
    actionCenterCustomers: true,
    statusOfCustomers: true,
  });

  const gridStyle = {
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    paddingTop: '16px',
    paddingBottom: '32px',
    mt: 4,
  };

  return (
    <Box sx={{ flexGrow: 1, p: isMobile ? '0' : '22px' }}>
      <Header uiMode={uiMode} />
      <VisibilitySettingsModal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        visibleComponents={visibleComponents}
        setVisibleComponents={setVisibleComponents}
      />
      <Grid container spacing={3}>
        {visibleComponents.collectionPerformance && (
          <Grid item xs={12}>
            <CollectionPerformance
              dateRanges={dateRangesCollections}
              setDateRanges={setDateRangesCollections}
              getCollectionsGraph={getCollectionsGraph}
              downSamplingSize={downSamplingSize}
              setDownSamplingSize={setDownSamplingSize}
            />
          </Grid>
        )}
        {visibleComponents.accountSummary && (
          <Grid
            item
            xs={12}
            sx={{
              mt: '36px',
              mb: 3,
            }}
          >
            <AccountSummary />
          </Grid>
        )}
      </Grid>

      <Grid container spacing={3} sx={gridStyle}>
        <OverviewSection
          onAddClick={() => {
            setModalOpen(true);
          }}
        />
      </Grid>

      <Grid container spacing={3} sx={gridStyle}>
        {visibleComponents.agingSummary && (
          <Grid item xs={12} md={8}>
            <AgingSummary agingSummary={agingSummary} loading={loading} />
          </Grid>
        )}
        {visibleComponents.collectabilityMatrix && (
          <Grid item xs={12} md={4}>
            <CollectMatrix />
          </Grid>
        )}
      </Grid>

      <Grid container spacing={3} sx={gridStyle}>
        {visibleComponents.topDebtor && (
          <Grid item xs={12} md={8}>
            <TopDebtor />
          </Grid>
        )}
        {visibleComponents.customerEngagement && (
          <Grid item xs={12} md={4}>
            <CustomerEngagement />
          </Grid>
        )}
      </Grid>

      <Grid container spacing={3} sx={{ ...gridStyle }}>
        {visibleComponents.recentPayments && (
          <Grid item xs={12} md={8}>
            <RecentPayments />
          </Grid>
        )}
        {visibleComponents.paymentsGraph && (
          <Grid item xs={12} md={4}>
            <PaymentsGraph />
          </Grid>
        )}
      </Grid>

      <Grid container spacing={3} sx={{ ...gridStyle }}>
        {visibleComponents.delinquentRate && (
          <Grid item xs={12} md={4}>
            <DelinquentRate selectedClientId={selectedClientId} />
          </Grid>
        )}
        {visibleComponents.topPaymentsMethod && (
          <Grid item xs={12} md={4}>
            <TopPaymentsMethod />
          </Grid>
        )}
        {visibleComponents.numberOfInvoices && (
          <Grid item xs={12} md={4}>
            <NumberOfInvoices />
          </Grid>
        )}
      </Grid>
      <Grid container spacing={3} sx={{ ...gridStyle }}>
        {visibleComponents.actionCenterCustomers && (
          <Grid item xs={12} md={4}>
            <ActionCenterTickets />
          </Grid>
        )}
        {visibleComponents.statusOfCustomers && (
          <Grid item xs={12} md={4}>
            <StatusOfCustomers />
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default Dashboard;
// dummy change to trigger build 3
